.col-product-info-desktop {
  display: none;
}

.product-wrapper {
  .mm-switch {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: unset;
    padding: 0.25rem 0.125rem;
    color: $ecru-95;
    background-color: unset;
    border-radius: $border-radius-8;

    .unique-size-hide {
      display: none;
    }
  }

  .line-item-wishilist {
    display: none;
  }

  .size-not-selected-message {
    font-size: 13px;
    font-weight: 500;
    line-height: 23.4px;
    text-align: left;
    color: #f44725;
  }

  .col-product-info {
    h6 {
      text-align: center;
      @include type(global-callout-1);
      margin-bottom: 0.5rem;
      /*
      a {
        color: $ecru-95;
      }
      */
    }
    .product {
      &-name {
        text-align: center;
        margin-top: 10px;
        @include type(mm-headline-3);
      }
      &-description {
        max-width: 304px;
        margin: 20px auto 0 auto;
        text-align: center;
        @include type(mm-headline-1);
      }
    }
  }
  .quantity-switch.mm-switch__toggle,
  .size-switch.mm-switch__toggle {
    margin-bottom: 24px;
    padding: 0;
    background-color: $white;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
    > .mm-form-select {
      width: 100%;
      select {
        text-align: center;
        background-position: right 1rem center;
        padding: 0 rem(28) 0 rem(16);
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: unset;
      }
      .attribute-label-value.single-val {
        text-align: center;
      }
    }
  }

  .size-switch.mm-switch__toggle.no-margin {
    margin-bottom: 0;
  }

  .add-to-cart-switch,
  .add-to-cart-switch.-active {
    background-color: $black;
    width: inherit;

    .add-to-cart {
      color: $white;
    }

    .add-to-cart-actions {
      width: inherit;

      .row.cart-and-ipay {
        justify-content: center;
      }
    }
  }
}

.stl-product-wrapper {
  margin-top: 63px;

  .right {
    .badge-container {
      line-height: 1px;
    }

    .mm-badge {
      margin-left: 10px !important;
    }

    .sales {
      font-weight: 500;
    }
  }
}

.recommendations-tile {
  .product {
    width: inherit;

    img {
      object-fit: cover;
    }
  }
}

@mixin tile-image-hover {
  & + .mm-product-card__info {
    .mm-product-card__info__default {
      opacity: 0;
    }
  }
  .mm-product-card__hero__default {
    display: none;
  }
  .mm-product-card__hero__hover {
    background-color: $ecru-05;
    visibility: visible;
    pointer-events: none;
    opacity: 1;
  }
}

.col-product-info {
  margin-top: 40px;
  margin-bottom: 112px;
}

/* temporary fix. Tiles need to be updated */
.recommendation-carousel-no-hover {
  .mm-product-card__info__hover {
    display: none !important;
  }
  .mm-product-card__info__default {
    opacity: 1 !important;
  }
}

.recommendations-carousel {
  $timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  .swiper-wrapper {
    justify-content: center;
  }

  .product-carousel-title {
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 58px 0;
    font-size: 11px;
    line-height: 17.6px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .mm-product-card {
    /* base for both brands */
    position: relative;

    &__hero {
      transition: transform 0.6s $timing-function;
      transform: translateY(0);
      cursor: pointer;
      display: block;
      margin-bottom: 0.5rem;
      backface-visibility: hidden;

      &__aspect {
        position: relative;
        overflow: hidden;
        padding-top: map-get($aspect-ratios, '2x3');
      }

      &__default,
      &__hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none !important;
        -webkit-touch-callout: none !important;
        -webkit-user-select: none !important;
        img {
          height: -webkit-fill-available;
          width: -webkit-fill-available;
        }
      }

      &__default {
        opacity: 1;
        display: block;
      }

      &__hover {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
      .mm-videoloop {
        height: 100%;
      }
    }

    &__info {
      position: relative;

      &__hover {
        position: absolute;
        top: 0.5rem;
      }

      button {
        @include button-reset();
        margin-left: 0.5rem;
      }
    }

    &__title,
    &__title:hover {
      color: $ecru-95;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    &__price {
      display: inline-flex;
      align-items: center;
      margin-top: 0.25rem;
      transform: translateY(0.125rem);
      transition-property: opacity transform;
      transition-duration: 500ms;
      transition-timing-function: $timing-function;

      .price {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        .default-price-container {
          display: flex;
          font-size: 0.812rem;
          margin: 0 auto;
          margin-bottom: 0.25rem;
        }
        .strike-through {
          margin-bottom: 0;
          margin-right: 0.5rem;
          text-decoration: line-through;
        }
        .mm-button {
          height: min-content;
        }
        .badge-container {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          margin-bottom: 0.25rem;

          .product-badge {
            margin-left: 0.5rem;
            &:empty {
              display: none;
            }
          }
        }
      }

      .-line-through {
        text-decoration: line-through;
      }

      span + span {
        margin-left: 0.55rem;
      }

      @include type('global-small-3', 'bold');
    }

    &__colors {
      .colors-box {
        padding: 0;
      }
      .doubled-color {
        .mm-color-swatch__inner {
          width: rem(4px);
        }
      }
    }

    /* modifier */
    &.-brand-mm {
      @extend .mm-product-card;
      @for $var from 1 through 10 {
        .mm-product-card__hero-#{$var} {
          @include mask('2x3', $var);
        }
      }

      .mm-product-card {
        &__info {
          text-align: center;
          text-transform: capitalize;
          &__hover {
            left: 0;
            right: 0;
            transform: translate3D(0, 0.125rem, 0);
            transition: transform 0.6s $timing-function;
            @include type('global-small-3');
          }
          .mm-product-card__price {
            @include type('global-control-productive-2');
          }
          .mm-product-card__title {
            @include type('mm-small-3');
          }
        }

        &__title {
          @include type('mm-small-3');
        }
      }
    }

    &.-brand-mm6 {
      @extend .mm-product-card;

      .mm-product-card {
        &__hero {
          position: relative;
          transform: translateZ(0);
          clip-path: none !important;

          &::after {
            content: '';
            border-radius: $border-radius-7;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-shadow: $box-shadow-md;
            opacity: 0;
            transition: opacity 0.6s $timing-function;
            display: none;
          }
        }

        &__info {
          background-color: $white;
          border-radius: $border-radius-7;
          padding: 0.625rem 1rem;
          text-align: left;
          text-transform: capitalize;

          &__hover {
            text-align: left;
            top: 0.625rem;
            left: 1rem;
            transform: translate3D(0, 0.125rem, 0);
            transition: transform 0.6s $timing-function;
          }

          .mm-product-card__price {
            @include type('global-control-productive-2');
          }
          .mm-product-card__title {
            @include type('mm6-small-3');
          }
        }

        &__info__hover,
        &__title {
          @include type('mm6-small-3');
        }
      }
    }

    .mm-product-card__title {
      text-transform: capitalize;
    }
    .single-element-content-image {
      display: block;
      &:not(.hover) {
        &:not(:focus):not(:hover),
        [data-whatintent='touch'] &:focus,
        [data-whatintent='mouse'] &:focus {
          & + .mm-product-card__info .mm-product-card__info__hover {
            @extend .visually-hidden;
          }
        }
      }

      [data-whatintent='keyboard'] &:focus,
      &:hover {
        @include tile-image-hover();
        transform: scale(0.975);
        .mm-product-card__hero__default {
          display: block;
        }
      }
      .badge-container {
        display: none;
      }
    }

    &.-inview {
      .mm-product-card__price {
        opacity: 1;
        transform: translateY(0);
        transition-duration: 2500ms;
      }
    }
  }
}

.col-product-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .product-component-container {
    font-family: 'Margiela Sans', 'Helvetica Neue', Arial, sans-serif;
    max-width: 358px;
    padding: 8px;
    width: 100%;
    border-radius: 16px;
    background-color: white;
    margin-bottom: 16px;

    .product-info {
      width: 100%;
      padding-bottom: 8px;
      border-bottom: 1px #f1f1f1 solid;
      display: flex;
      flex-flow: wrap;
    }

    .left {
      width: 30%;

      img {
        border-radius: 10%;
        height: 154px;
        object-fit: cover;
      }
    }

    .right {
      position: relative;
      width: 70%;

      .product-name-link {
        display: flex;
        flex-flow: nowrap;
        font-size: 14px;
        margin: 4px 0 12px 10px;
      }

      .product-name {
        width: 60%;
        font-size: 14px;
        font-weight: 600;
      }

      .product-link {
        width: 40%;
      }

      .product-color {
        font-weight: 600;
        font-size: 14px;
        margin: 15px 0 0 10px;
      }

      .product-price {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .wishlist {
        position: absolute;
        bottom: 10px;

        .mm-button {
          padding: 0;
          margin-left: 8px;
          height: 0;
        }
      }
    }

    .add-to-cart-add-to-wishlist {
      width: 100%;
      padding-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }
}

$modal-header: 4.5rem;
$modal-footer: 5rem;

a.link-lighter {
  color: $ecru-50;
}

.wrapper-pdp {
  background-color: var(--mm-body-bg);
  position: relative;
  z-index: 1;
  .product-slide {
    .swiper-zoom-container {
      // height: 100%;
      width: 100%;
      align-content: center;
    }
  }

  .mm-form-message {
    background: unset;
    display: block;
  }
}

.product-wrapper {
  .breadcrumbs-hero-internal {
    margin: 2rem auto 4rem;
    .breadcrumb-component {
      display: flex;
      justify-content: center;
      .breadcrumb-item {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.recommendations-tile:hover {
  .single-element-content {
    transform: scale(0.975);
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  .tile-image-link {
    transform: scale(1.025) !important;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
}

.product-breadcrumb {
  text-align: center;
  @include type(global-callout-1);
  margin-bottom: 0.5rem;
  a {
    color: $ecru-95;
  }
}
.details-column-content {
  margin: 0 auto;
  top: 0;
}
.product-name {
  .title {
    @include type(mm-headline-3);
  }
}
.price-badge-wishlist {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 1rem 0 3.5rem;
  .badge-container {
    display: flex;
    .product-badge {
      margin-left: rem(8);
    }
  }
  .badge-wishlist-box {
    align-items: center;
    display: flex;
    justify-content: center;
    .add-to-wish-list {
      padding: 0;
      height: rem(16);
      width: rem(26);
      margin: 0.15rem 0 0 0.25rem;

      &:not(.active) .wishlist-icon-active {
        display: none;
      }
      &.active .wishlist-icon {
        display: none;
      }
    }
  }
  .product-price {
    display: flex;
    justify-content: center;
    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > span {
        display: flex;
        margin-bottom: rem(16);

        &.price-with-discount,
        &.default-price-container {
          margin-bottom: rem(4);
        }
      }

      .discount-price,
      .strike-through {
        margin-right: rem(8);
      }

      .discount-percentage {
        margin-left: rem(8);
        color: #6b6b6b;
      }

      .discount-disclaimer {
        font-size: rem(11);
        color: #6b6b6b;
      }
    }
  }
}

.product-carousel {
  width: 100% !important;
}

.add-to-cart-add-to-wishlist {
  margin-bottom: rem(12px);
}
.attribute-size.with-short-text {
  position: relative;
  .fake-placeholder {
    display: flex;
    align-items: center;
  }
  select {
    color: transparent;
  }
}
.fake-placeholder {
  position: absolute;
  left: 0.75rem;
  pointer-events: none;
  height: 100%;
  display: none;
}

.quantity-switch,
.size-switch,
.add-to-cart-switch {
  width: 100%;
}

.add-to-cart-add-to-wishlist {
  &.only-size {
    display: flex;
    justify-content: center;
    .mm-switch {
      width: 50%;
      max-width: rem(180);
      .size-switch {
        width: 100%;
      }
    }
  }
}

.add-to-cart-switch {
  .product-notify {
    display: none;
  }
  &.show-notify-me {
    background-color: $black;
    .add-to-cart-actions {
      display: none;
    }
    .product-notify {
      display: block;
      width: inherit;
      .add-to-cart {
        display: none;
        color: $white;
      }
      .add-to-cart.js-notify-me-button {
        display: block;
        color: $white;
      }

      .row.cart-and-ipay {
        width: inherit;
        justify-content: center;

        .notify-me-modal-button {
          width: inherit;
        }
      }
    }

    &.-active {
      .product-notify {
        .add-to-cart.js-notify-me-button {
          display: none;
        }
        .add-to-cart:not(.js-notify-me-button) {
          display: block;
        }
      }
    }
  }
}

.product-variants {
  margin-bottom: 4rem;
}

.product-variants-box {
  position: relative;
  flex-flow: wrap;
}

.product-color-variant {
  @include type(global-control-productive-2);
  display: none;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  bottom: -32px;
  margin: 0;
  &.selected-description {
    display: block;
  }
}

.variation-attribute.attribute-color {
  display: none;
}
.product-size-select {
  .attribute-label-value {
    margin-bottom: 0;
  }
  select {
    background-color: transparent;
    border: none;
  }
}

.add-to-cart {
  @extend .mm-button;
  @extend .-text;
}

.add-to-wish-list {
  &:not(.active) .wishlist-icon-active {
    display: none;
  }
  &.active {
    // .wishlist {
    //   display: none;
    // }
  }
}

.product-description {
  position: relative;
}
.product-description-controls {
  &[aria-expanded='false'] {
    .product-description-less {
      display: none;
    }
    // & ~ .product-description-content {
    //   max-height: calc(var(--lh) * var(--max-lines));
    // }
  }
  &[aria-expanded='true'] {
    // & ~ .product-description-content {
    //   max-height: none;
    // }
    .product-description-more {
      display: none;
    }
  }
}
.product-description-controls {
  @include type(global-control-productive-1);
  border: none;
  bottom: rem(26);
  color: $ecru-40;
  height: 1.5rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--mm-body-bg) 0.75rem, var(--mm-body-bg) 100%);
  position: absolute;
  z-index: 1;
  inset-block-end: 0;
  inset-inline-end: 0;
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
}
// .product-description-content {
//   @include type(mm-body-1);
//   margin-top: 3.5rem;
//   --max-lines: 2;
//   --lh: 1.575rem; //desktop line-height in rem
//   position: relative;
//   overflow: hidden;
// }
.product-description-box {
  @include type(mm-body-1);
  --max-lines: 3;
  --lh: 1.575rem;
  margin-top: 3.5rem;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;

  &.opened-text {
    --lh: 0;
    max-height: none;
    padding-bottom: rem(8);
    .product-description-controls {
      bottom: 0;
    }
  }
}

.findinstore,
.bookanappointment {
  button.mm-button {
    width: 100%;
    border-color: #e9e9e4;
  }
}
.bookanappointment {
  margin-bottom: 0.5rem;
}
.swiper {
  width: 600px;
  height: 300px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-carousel-main .swiper-slide {
  &:not(.js-editorial-has-editorial) {
    cursor: pointer;
  }
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}

.swiper.thumbnails {
  height: inherit;
  max-height: rem(544);
  overflow: hidden;
  transform: translateY(50%);
  // transform: translateY(40%);
  width: auto;
  padding: 0.5rem 0;
  position: relative;
}
//
.product-wrapper.fullscreen {
  .wrapper-pdp {
    position: static;
  }
  .thumbs-carousel-col {
    position: static;
  }
  .swiper.thumbnails {
    position: fixed;
    z-index: 103;
    left: 0;
    top: 0;
    width: 200px;
  }

  .product-slide-thumb-video {
    display: none;
  }
}

.thumbs-marker {
  // position: absolute;
  left: 50%;
  // transform: translateX(-50%);
  height: 2px;
  opacity: 0.06;
  z-index: 2;
  background-color: $black;
  display: none;
  width: rem(80);
  &.top {
    top: 0;
    display: none !important;
  }
  &.bottom {
    bottom: 0;
  }
  &.shown {
    display: block;
  }
}

.thumbnail-button {
  border: 2px solid transparent;
  padding: rem(4);
  background-color: transparent;
  position: relative;
  .swiper-slide-thumb-active &,
  .selected & {
    border-color: $info-50;
  }

  max-width: rem(76);
  max-height: rem(108);
  width: 84%;
  height: 101%;
  overflow: hidden;

  > * {
    max-width: rem(64) !important;
    max-height: 6.2rem !important;
    height: 101% !important;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
  }
}

.swiper-wrapper {
  padding: 0;
  margin: 0;
}
.image-carousel-col {
  // overflow: hidden;
}

.product-brand-mm {
  @for $var from 1 through 10 {
    .img-mm-mask-#{$var} {
      @include mask('9x16', $var);
    }
  }
}

.swiper .swiper-pagination {
  width: 40%;
  display: flex;
  position: static;
  margin: 2rem auto;
  .swiper-pagination-bullet {
    width: auto;
    height: 2px;
    margin: 0;
    border-radius: 0;
    flex-grow: 1;
    &.swiper-pagination-bullet-active {
      background-color: $ecru-95;
    }
  }
}

.image-carousel-col .swiper .swiper-pagination {
  width: 40%;
  display: flex;
  position: static;
  margin: 2rem auto;
  .swiper-pagination-bullet {
    width: auto;
    height: 2px;
    margin: 0;
    border-radius: 0;
    flex-grow: 1;
    &.swiper-pagination-bullet-active {
      background-color: $ecru-95;
    }
  }
}

.product-fullscreen-images-overlay {
  display: none;
  .product-wrapper.fullscreen & {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 102;
    background-color: $white;
    overflow: scroll;
    padding: 0;
  }

  .product-fullscreen-zoom {
    &,
    &:hover,
    &:active {
      /* Écru 05 Transparent/50 */
      background: $ecru-50-50;
      box-shadow: 0 24px 96px rgba(0, 0, 0, 0.14);
      backdrop-filter: blur(20px);
    }
    padding: 0;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .product-zoom-button {
      border: none;
      background-color: transparent;
      color: $black;
      i {
        vertical-align: middle;
      }
      &[data-zoom='minus'] {
        padding: 0 rem(12) 0 rem(20);
      }
      &[data-zoom='plus'] {
        padding: 0 rem(20) 0 rem(12);
      }
    }
  }
  .product-fullscreen-images {
    min-height: 100vh;
  }
  .product-fullscreen-close-button {
    position: fixed;
    top: rem(24);
    right: rem(24);
    z-index: 1;
    padding: rem(20);
  }
}

.accordion-group-list-container {
  ul,
  .accordion-list-box {
    list-style-type: none;
    padding: 0;

    .accordion-trigger {
      border: 1px solid;
    }

    li:empty {
      display: none;
    }
    .accordion-group-list-item {
      padding: rem(16px);
      a {
        color: $ecru-95;
      }
    }
    .accordion {
      background-color: transparent;
      transition: background-color 0.2s ease-in-out;
      .accordion-icon {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &.is-active,
      &:hover {
        background-color: $pdp-accordion-bg;
        .accordion-icon {
          opacity: 1;
        }
      }
      &:not(.is-active) {
        .accordion-icon-expanded {
          display: none;
        }
        .accordion-icon-hidden {
          display: block;
        }
      }
      &.is-active {
        .accordion-icon-expanded {
          display: block;
          position: relative;
          svg {
            position: absolute;
          }
        }
        .accordion-icon-hidden {
          display: none;
        }
      }
    }
    .accordion-trigger {
      border: none;
      background-color: transparent;
      text-align: left;
      padding: 0;
      width: 100%;
      color: get-color('ecru-95');
      .accordion-icon {
        float: right;
      }
    }
    .accordion-content {
      padding-top: rem(12px);
      border-top: 1px solid $ecru-30;
      margin-top: rem(12px);
    }
  }
  .product-reassurances-item {
    margin-bottom: 1rem;
    // .accordion-trigger {
    // @include type('global-control-productive-3');
    // display: flex;
    // justify-content: space-between;
    // > span {
    //   display: flex;
    //   > i {
    //     margin-right: rem(11);
    //   }
    // }
    // }
    .product-info-id {
      margin-bottom: rem(8);
    }
    .product-info-details {
      @include type('global-control-productive-3');
      margin-bottom: 0.25rem;
    }
    #content-shipping-info {
      @include type('global-body-1');
    }

    .underline {
      border-bottom: 1px solid $black;
    }
  }
  .product-reassurances-copyid {
    margin-bottom: rem(8);
  }
  .product-reassurances-composition {
    span {
      display: block;
    }
  }

  &.size-guidelines-types-container {
    .accordion {
      .accordion-icon {
        opacity: 1;
      }
      .accordion-trigger {
        @include type('global-control-productive-3');
        font-weight: 400;
      }
      &.is-active {
        background: $ecru-05;
        /* Shadow/Default/Small/ */
        box-shadow: $box-shadow-sm;
        border-radius: 10px;
      }
    }
  }
}

.error-message {
  display: none;
}

.accordion {
  &.is-enabled {
    .accordion-content {
      transition: all ease-in 0.3s;
      max-height: 0;
      display: none;
      opacity: 0;
    }

    &.is-active {
      .accordion-content {
        display: block;
        max-height: 20000px;
      }

      &.is-visible {
        .accordion-content {
          opacity: 1;
        }
      }
    }
  }
}

.accordion-trigger {
  &[aria-expanded='true'] {
    .button-icon {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }
}

.form-notify-me-email {
  label {
    @include type('global-small-1');
    .privacy-text {
      margin-top: -0.188rem;
    }
  }
}

.mm-form-message {
  color: $danger-50;
  background-color: get-color('danger-60', 10);
}

// .success-message {
//   align-items: center;
//   display: none;
//   margin: 0;
//   max-width: unset;
//   width: 17.375rem;
//   &.success {
//     display: flex;
//   }
// }

.fullscreen-modal {
  .modal-container {
    height: calc(100vh - 1.75rem);
    max-height: calc(100% - 1.75rem);
    width: 100%;
    position: absolute;

    .modal-close {
      height: 2rem;
      width: 2rem;
    }

    .modal-content {
      // max-height: 79vh;
      max-height: calc(100vh - 9rem);
      overflow: auto;
      // padding-top: 84px;
      padding-top: rem(32);
    }

    .submit-button {
      background-color: get-color('ecru-05');
      bottom: rem(20px);
      left: rem(16px);
      position: absolute;
      width: calc(100% - 2rem);
    }

    .modal-header {
      align-items: center;
      display: flex;
      .modal-title,
      h2 {
        @include type('global-callout-2');
        margin-bottom: 0;
        margin-right: 1.313rem;
        padding: 0;
      }
      .reset-filters-button {
        text-transform: capitalize;
      }
    }
  }
  .horizontal-row {
    border-top: 1px solid $ecru-10;
    margin-bottom: 16px;
    margin-top: 20px;
    &.hidden {
      display: none;
    }
  }
}

.mm6-body {
  .wrapper-pdp {
    .product-name {
      .title {
        @include type(mm6-headline-3);
      }
    }
    .product-description-content {
      @include type(mm6-body-1);
    }
  }
}

.size-guidelines-trigger-box {
  display: flex;
  justify-content: right;
  margin-bottom: 0.781rem;
  button {
    @include type('global-control-productive-1');
    background: transparent;
    border: none;
    text-transform: capitalize;
    color: #1a1a1a;
    text-decoration: underline;
    span {
      text-decoration: none;
    }
  }
}

#size-guidelines-modal {
  .modal-content {
    // max-height: 100vh;
    $modal-header: 4.5rem;
    $modal-footer: 5rem;
    max-height: 85vh;
    padding: 0.5rem;
    padding-top: 2.75rem;

    .modal-content-gudielines {
      width: 100%;
    }
  }
  .accordion-group-list-container {
    &.size-guidelines-types-container {
      .accordion {
        margin-bottom: 0.35rem;
        padding: 0.65rem;

        .accordion-icon {
          opacity: 1;
        }
        .accordion-trigger {
          @include type('global-control-productive-3');
          font-weight: 400;
        }
        &.is-active {
          background: $ecru-05;
          box-shadow: $box-shadow-sm;
          border-radius: 10px;
        }
      }
    }
    .product-measurement-description {
      @include type('global-small-3');
      margin-top: 2.5rem;
    }
  }
}

#size-guidelines-modal {
  .fitguide__interactive {
    > .row {
      margin-left: 0;
    }

    .loading-label,
    .product-measures-description {
      @include type('global-small-3');
    }
    .table-img-container {
      flex-flow: column-reverse;
      margin-top: 2.5rem;
      img {
        width: 100%;
      }
    }

    .choose-size-radio-input-row {
      #choose-your-size {
        @include type('global-control-productive-3');
      }
      > div {
        padding-left: 0;
      }
      label {
        @include type('global-control-productive-2');
      }
    }
    .choose-size-and-unit-measure {
      width: 100vw;
    }
    .measuring-guide__size-table {
      margin-top: 2.5rem;
      .single-detail-content {
        display: flex;
        flex-flow: column-reverse;
        .table-details-label {
          margin-bottom: 1.5rem;
          text-align: left;
          > p {
            margin-bottom: 0;
          }
          .measure-description {
            @include type('global-small-2');
          }
          .measure-title {
            @include type('global-callout-1');
          }
        }

        > span {
          align-items: center;
          height: 16px;
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 8px;
          width: min-content;
          .value-letter {
            align-items: center;
            background-color: $info-50;
            border-radius: 4px;
            color: $ecru-05;
            display: flex;
            font-size: 9px;
            font-weight: 600;
            height: 16px;
            justify-content: center;
            margin-right: 0.625rem;
            width: 16px;
          }
          .value-size,
          ._measures-cm,
          ._measures-in {
            @include type('global-caption-1');
          }
        }
      }
    }
  }
  .body-measures-accordion {
    .measuring-guide {
      .text-measurements-guide {
        @include type('global-small-3');
        margin-bottom: 0;
        margin-top: 0.25rem;
      }
      .table {
        overflow-x: auto;
      }
      table {
        width: 100%;
      }
      thead {
        th {
          @include type('global-control-productive-2');
          text-align: center;
          // width: 33%;
          min-width: 7.45rem;
        }
      }
      tbody {
        td {
          @include type('global-control-productive-2');
          text-align: center;
        }
      }
    }
  }
  .gender-size-conversion-accordion {
    position: relative;
    table {
      @include type('global-control-productive-2');

      th {
        padding: 1rem 1.15rem;
        p {
          margin-bottom: 0;
        }
        &.col-separator {
          padding: 1rem 0;
          .separator-element {
            border-left: 1.5px solid $ecru-20;
          }
        }
      }
    }
    .opacity-mask {
      width: 56px;
      bottom: 0;
      position: absolute;
      top: 47px;
      &.left-position {
        left: 1px;
        background: linear-gradient(to right, $white 0%, rgba(233, 233, 233, 0) 100%);
        border-bottom-left-radius: 0.75rem;
      }
      &.right-position {
        right: 1px;
        background: linear-gradient(to left, $white 0%, rgba(233, 233, 233, 0) 100%);
        border-bottom-right-radius: 0.75rem;
      }
    }
    .gender-size-conversion {
      overflow: auto;
    }
  }

  .product-category-sizechart-accordion {
    position: relative;
    .product-sizechart-header {
      font-weight: 700;
      font-size: 15px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 10px;
      height: 40px;
    }
    table {
      @include type('global-control-productive-2');

      th {
        padding: 1rem 0.5rem;
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .mm-switch {
    margin-bottom: 40px;
    margin-top: 16px;
    &__toggle {
      padding: 0.469rem 0.813rem;
    }
  }
}

html.product-main-overlay {
  overflow: hidden;
}
body.product-main-overlay {
  overflow: hidden;
  touch-action: pan-x pan-y;
}

.strike-through {
  text-decoration: line-through;
  margin-bottom: 0;
  color: $ecru-50;
}

.product-photo-retouch {
  color: $ecru-40;
}

.interlink-products-wrapper {
  margin: 0 0.1rem;
  .mm-notification {
    width: 100%;
    background-color: $ecru-05;
    height: 100%;
    padding: 0.4rem 0;
    .mm-notification__message {
      width: 100%;
      height: auto;
      font-size: rem(13);
      .mm-icon {
        margin-right: 0.2rem;
      }
    }
    .mm-notification__actions {
      .mm-button {
        background-color: $ecru-95-10;
        color: $ecru-95;
      }
    }
  }
}
.pdp-badges {
  align-items: center;
  display: flex;
}

.modal-preorder {
  button {
    @include type(global-control-expressive-1);
  }
}

.preorder-release-date {
  .mm-form-message {
    display: inline-block;
    span {
      cursor: pointer;
    }
  }
}

// .product-wrapper .recommendations {
.product-recommendations {
  margin-top: 3rem;

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    width: 40%;
  }
  .experience-layouts-MMCarousel .swiper-pagination .swiper-pagination-bullet {
    max-width: none;
  }

  .swiper-wrapper {
    justify-content: center;
  }

  .container-carousel {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 20.8px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .tile-image {
    max-width: 100%;
  }

  .mm-product-card__hero__hover {
    display: none;
  }
}

.product-price {
  .price {
    font-size: 14px;
  }
}

.swiper.thumbnails {
  width: rem(76);
  margin: 0 auto;
}

.thumbnail-button {
  .vjs-player > div:first-of-type {
    --ar: 150%;
    --ar-sm: 150%;

    background: #f7f7f2;
    width: 100%;
    overflow: hidden;
    padding-bottom: var(--ar-sm, --ar);
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      max-width: 100%;
      display: block;
    }
  }
  .vjs-player > div:first-of-type {
    video {
      width: rem(64);
      height: rem(96);
    }
  }
}
//

.wrapper-pdp {
  --ar: 150%;
  --ar-sm: 150%;

  .thumbnails .vjs-tech,
  .product-carousel-main .mm-videoloop .vjs-tech {
    width: 100%;
    left: 0;
    transform: none;
    object-fit: cover;
  }

  .mm-videoloop {
    position: absolute;
    height: 100%;
    background: #f7f7f2;
  }

  .vjs-player > div:first-of-type {
    background: #f7f7f2;
    position: relative;
    padding-bottom: var(--ar-sm, --ar);
  }

  .product-slide {
    .swiper-zoom-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: fit-content;
      height: 100%;
      max-width: 100%;
    }
  }

  .thumbs-carousel-col {
    img {
      width: 100%;
    }
  }
}

#product-show {
  .main--layout-page {
    padding-top: 0;
  }

  .wrapper-pdp {
    overflow: hidden;
    margin-bottom: 100px;
    padding-top: 1rem;
  }

  // .col-images,
  // .col-details {
  //   padding-top: calc(var(--header-height) + 1rem);
  // }
}

.recommendations {
  .cards-carousel .card-slide .single-element-content-image {
    box-shadow: none;
  }
}

// IBT-3745
#product-show {
  .wrapper-pdp {
    overflow: visible; // sticky children
  }

  // .thumbs-carousel-col,
  // .col-details {
  //   position: sticky;
  //   top: 0;
  // }

  .thumbs-carousel-col {
    flex-direction: column;

    // From our perspective we would like to vertically centre the container inside the viewport (minus the menu height) so it sits perfectly vertically centred.
    // justify-content: start;
    justify-content: center;

    // Also this happens for me when I scroll on desktop in Safari. The mini-menu should stay fixed not scroll at first
    // margin-top: calc(var(--header-height) + 1rem);
    // 2: Vertically align in the viewport minus the menu height (vh - menu)
    // padding-top: calc(var(--header-height) + 1rem);
    padding-top: var(--header-height);
  }

  .swiper.thumbnails {
    transform: none;

    &:not(.swiper-initialized) {
      height: auto;

      .swiper-slide {
        height: auto;
      }
    }
  }

  .swiper.product-carousel-main {
    overflow: hidden;
  }
}

[lang='ja-jp'] {
  .product-name {
    .title {
      font-size: 26px;
      letter-spacing: -0.4px;
    }
  }

  .product-description-box {
    font-size: 17px;
    --lh: 1.375rem;
  }
}
