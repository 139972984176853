.experience-layouts-MMCardsCarousel {
  padding: 4rem 0;
}

.cards-carousel {
  min-height: rem(667);
  .product {
    .second-image {
      display: none;
    }
    .mm-product-card__info__hover {
      display: none;
    }

    .default-price-container {
      display: flex;
      justify-content: center;
      margin-right: 0.5rem;
      .strike-through {
        margin-right: 0.5rem;
      }
    }

    .mm-product-card__price {
      @include type('global-control-productive-2');
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
    .mm-product-card__title {
      @include type('mm-small-3');
      font-size: 1rem;
    }

    .tile-body {
      padding-top: rem(14);
      padding-bottom: rem(14);
    }

    &.-brand-mm6 {
      .mm-product-card__price {
        @include type('global-control-productive-2');
        justify-content: flex-start;
      }
      .mm-product-card__title {
        @include type('mm6-small-3');
      }
    }

    .price.default-option {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  [class*='product-card'] {
    @include media-hover {
      .mm-product-card__hero:hover {
        transform: none;
      }
    }
  }
}

.cards-carousel-list {
  @extend .unstyled-list;
  margin: 2rem 0;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  position: relative;
  > .experience-assets-MMproduct {
    background-color: transparent;
  }

  .product-brand {
    text-align: center;
  }
  .mm-product-card__info__default {
    margin-bottom: 1rem;
  }

  .mm-product-card.-brand-mm6 .single-element-content-detail-description .title-wishlist-wrapper {
    justify-content: center;
    .add-to-wish-list {
      padding: 0;
    }
  }
}

.cards-carousel {
  .card-slide {
    position: absolute;
    width: 100%;
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, z-index 0s ease 0s;
    overflow: visible;

    > * {
      margin: 0 auto;
      width: 100%;
      max-width: rem(344);
      height: max-content;
    }

    .experience-component:not(.experience-assets-image_video),
    .tile-body {
      transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, z-index 0s ease 0s;
    }

    &:nth-child(1) {
      z-index: 3;
      opacity: 1;

      .experience-component:not(.experience-assets-image_video),
      .tile-body {
        opacity: 1;
      }
    }
    &:nth-child(2) {
      z-index: 2;
      transform: translate3d(0, calc(-7.3% + -20px), 0) scale3d(0.85, 0.85, 1);
    }
    &:nth-child(3) {
      z-index: 1;
      transform: translate3d(0, calc(-14.66% + -40px), 0) scale3d(0.7, 0.7, 1);
    }

    &:nth-child(n + 4) {
      z-index: 0;
      opacity: 0;
      transform: translate3d(0, calc(-22% + -60px), 0) scale3d(0.56, 0.56, 1);
    }

    &:nth-child(n + 2) {
      .experience-component:not(.experience-assets-image_video):not(.experience-assets-MMproduct),
      .tile-body {
        opacity: 0;
      }
    }

    .image-video-component {
      position: relative;
      width: 100%;
      height: rem(516);
    }

    .default-image {
      position: relative;
      width: 100%;
      height: rem(516);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: $border-radius-12;
      user-select: none;
      pointer-events: none;
    }
    .mm-videoloop {
      border-radius: $border-radius-12;
      overflow: hidden;
      isolation: isolate;
    }

    .experience-assets-MMbutton,
    .experience-assets-MMheadingGroup,
    .experience-assets-MMheadingGroup2CTA {
      padding-top: rem(14);
      padding-bottom: rem(14);
    }

    .experience-component {
      overflow: visible;
      background: transparent;
    }

    .single-element-content-image {
      box-shadow: 0 32px 96px rgb(0 0 0 / 10%);
      border-radius: $border-radius-12;
    }

    .experience-assets-image_video {
      box-shadow: 0 32px 96px rgb(0 0 0 / 10%);
      border-radius: $border-radius-12;
    }
  }
  .js-carousel-cards-controls {
    display: flex !important;
    justify-content: space-around;
    margin-bottom: 15px;
    .add-to-wish-list,
    .mm-cards-back,
    .mm-cards-next {
      text-align: center;
      width: 38px;
      height: 38px;
      border: 1px solid $ecru-30;
      border-radius: 99px;
      align-self: center;
      position: relative;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .mm-cards-back,
    .mm-cards-next {
      i {
        pointer-events: none;
      }
    }

    .add-to-wish-list {
      padding-top: 0;
      margin: 0;
    }
  }

  .single-element-content-detail {
    margin-top: 0 !important;
    padding-top: 0 !important;

    .single-element-content-detail-description {
      .add-to-wish-list {
        display: none !important;
      }
    }
  }
}
