.message-notification {
  display: none;
  color: get-color(ecru-05);
  background-color: get-color('ecru-60');
  border-radius: $border-radius-full;
  @include type(global-control-productive-3);
  font-size: 0.875rem;
  max-width: rem(250);
  width: 100%;
  min-height: 3rem;
  padding: 0.25rem 1.5rem;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;

  &.-show {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.add-to-cart {
    background-color: #f7f7f2;
    text-align: inherit;
    border-radius: $border-radius-5;
    max-width: inherit;
    height: auto;
    color: black;
    display: none;
    padding: 8px 20px;
    flex-direction: column;
    z-index: 10;

    &.-show {
      display: flex;
    }

    .notification-left {
      order: 2;

      .prior-best-price-component,
      .prior-best-price-discount {
        display: none;
      }
    }

    .notification-close {
      background-color: get-color('ecru-20');
      height: 2rem;
      width: 2rem;
      border-radius: 0.5rem;
      order: 1;
      align-self: end;
      margin: 8px;
    }

    .cart-body-section {
      p {
        padding-left: 10px;
      }
    }

    .cart-body-section .product-wrapper .product-info-details {
      .product-name-badge > a {
        padding: 0;
      }

      .product-name-badge {
        margin-bottom: 24px;
      }

      .product-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
      }

      .bag-product__details-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 150px;
        width: 100%;
        display: block;
      }
    }

    .detail-attribute {
      flex: 1;
    }
  }

  .notification-close {
    // top: 50%;
    margin-right: -0.5rem;
    // position: absolute;
    // transform: translateY(-50%);
    background-color: get-color('ecru-60');
    color: get-color('ecru-05');
    //
  }

  .notification-box-text-icon {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .cart-body-section .product-wrapper .cart-item-grid {
    grid-template-columns: rem(103) rem(75) 1fr;
  }

  .cart-body-section .product-wrapper .product-quantity-wrapper {
    margin-bottom: 2.5rem;
  }

  .cart-body-section .product-wrapper .product-prices-wrapper {
    margin-bottom: 2.5rem;

    .line-item-total-price {
      .price {
        row-gap: 8px;
      }
    }
  }

  .apple-pay-cart-button.apple-pay-button {
    width: 100%;
    border-radius: 14px;
    height: 48px;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: unset;
    -apple-pay-button-style: white-outline;
    --apple-pay-button-height: 48px;
    --apple-pay-button-border-radius: 14px;
    --apple-pay-button-padding: 9px 0px;
    --apple-pay-button-box-sizing: border-box;
    box-shadow: 0 1px 2px 0 #0000003D;
  }

  .cart-terms-link {
    font-weight: 500;
    font-size: 11px;
    line-height: 180%;
    letter-spacing: 0%;
    padding: 0;
    color: #767674;
    margin-bottom: 7px;

    a {
      border-bottom: 1px solid #767674;
    }
  }
}
