.message-notification {
  width: auto;
  bottom: 1.5rem;
  max-width: rem(380);
  min-width: rem(230);

  .apple-pay-cart-button.apple-pay-button {
    display: none;
  }

  .cart-terms-link {
    display: none;
  }
}
